import { Box } from '@material-ui/core';
import React from 'react';
import '../../styles/Base.scss';
import Footer from './Footer';
import Header from './Header';
import LayoutBase, { Props } from './LayoutBase';

export default class MainLayout extends LayoutBase {
    constructor(props: Props) {
        super(props);
    }

    public render() {
        return (
            <Box display="flex" flexDirection="column">
                <Header currentUrl={this._currentUrl} language={this._pageLanguage as string} isSubPage={true} />
                <Box component={'section'} id="main" className="wrapper" display="flex">
                    <Box mt={{ xs: 8, sm: 0 }} className="inner" width="100%">
                        {this.props.children}
                    </Box>
                </Box>
                <Footer />
            </Box>
        );
    }
}

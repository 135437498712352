import { Box, Icon } from '@material-ui/core';
import React from 'react';
import classNames from 'classnames';
import styles from '../../styles/Header.module.scss';
import { AvailableLanguage, AVAILABLE_LANGUAGES } from '../../models/Statics';
import { Utils } from '../../models/Utils';
import { RESOURCES } from '../../config/resources';

// eslint-disable-next-line @typescript-eslint/ban-types
type Props = {
    isSubPage?: boolean;
    language: string;
    currentUrl?: string;
};

export default class Header extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    public render() {
        return (
            <Box
                component={'header'}
                id={styles.header}
                className={classNames({
                    [styles.subpageHeader]: this.props.isSubPage,
                })}
            >
                <Box className={styles.inner}>
                    <a href={`/${this.props.language}/home`} className={styles.logo}>
                        Resurrection Power Ministries
                    </a>
                    <Box id={styles.nav} component={'nav'}>
                        <a href={`/${this.props.language}/event/registration`}>
                            {RESOURCES[`event_registration_menu_${this.props.language}`]}
                        </a>
                        <a href={`/${this.props.language}/contact`}>
                            {RESOURCES[`contact_menu_${this.props.language}`]}
                        </a>
                        {AVAILABLE_LANGUAGES.map((l, i) => {
                            if (l !== (this.props.language as AvailableLanguage)) {
                                return (
                                    <a key={i} href={`/${l}${this.props.currentUrl}`}>
                                        {Utils.CountryToFlag(l === 'en' ? 'GB' : l.toLocaleUpperCase())}
                                    </a>
                                );
                            }
                        })}
                    </Box>
                    <a href="#navPanel" className={styles.navPanelToggle}>
                        <Icon className="fa fa-bars" />
                    </a>
                </Box>
            </Box>
        );
    }
}

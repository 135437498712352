import React from 'react';
import { Router } from 'react-router-dom';
import history from './services/history/History';
import Routes from './routes/Routes';
import { StylesProvider, ThemeProvider } from '@material-ui/core';
import rpmTheme from './themes/rpm.theme';

class App extends React.Component {
    render() {
        return (
            <ThemeProvider theme={rpmTheme}>
                <StylesProvider injectFirst={true}>
                    <Router history={history}>
                        <Routes />
                    </Router>
                </StylesProvider>
            </ThemeProvider>
        );
    }
}

export default App;

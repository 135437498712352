import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { RESOURCES } from '../../config/resources';
import styles from '../../styles/HomeLayout.module.scss';
import Footer from './Footer';
import Header from './Header';
import LayoutBase, { Props } from './LayoutBase';

export default class HomeLayout extends LayoutBase {
    constructor(props: Props) {
        super(props);
    }

    public render() {
        return (
            <Box display="flex" flexDirection="column">
                <Header currentUrl={this._currentUrl} language={this._pageLanguage as string} />
                <Box id={styles.banner} component={'section'}>
                    <Typography variant="h1">{`${RESOURCES[`welcome_intro_${this._pageLanguage}`]}`}</Typography>
                    <Typography color="inherit" variant="h3">{`${
                        RESOURCES[`home_intro_${this._pageLanguage}`]
                    }`}</Typography>
                </Box>
                <Box component="section" id="one" className="wrapper">
                    <Box className="inner"> {this.props.children}</Box>
                </Box>
                <Footer />
            </Box>
        );
    }
}

/* eslint-disable react/require-render-return */
import React from 'react';
import { Switch, RouteComponentProps } from 'react-router-dom';
import HomeLayout from '../components/layout/HomeLayout';
import MainLayout from '../components/layout/MainLayout';
import Contact from '../pages/contact/Contact';
import Registration from '../pages/event/Registration';
import Home from '../pages/home/Home';
import Privacy from '../pages/privacy/Privacy';
import RouteWithLayout from './RouteWithLayout';

export type RpmRoute = {
    Exact: boolean;

    Path: string;
    Component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
    Layout: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
};

class Routes extends React.Component {
    private publicRoutes: RpmRoute[] = [
        {
            Layout: HomeLayout,
            Component: Home,
            Exact: true,
            Path: '/:language?/home',
        },
        {
            Layout: HomeLayout,
            Component: Home,
            Exact: true,
            Path: '/:language?',
        },
        {
            Layout: HomeLayout,
            Component: Home,
            Exact: true,
            Path: '/',
        },
        {
            Layout: MainLayout,
            Component: Registration,
            Exact: true,
            Path: '/:language?/event/registration',
        },
        {
            Layout: MainLayout,
            Component: Contact,
            Exact: false,
            Path: '/:language?/contact',
        },
        {
            Layout: MainLayout,
            Component: Privacy,
            Exact: false,
            Path: '/:language?/privacy',
        },
    ];

    render() {
        return (
            <Switch>
                {this.publicRoutes.map((route, i) => {
                    return (
                        <RouteWithLayout
                            key={i}
                            exact={route.Exact}
                            path={route.Path}
                            component={route.Component}
                            layout={route.Layout}
                        />
                    );
                })}
            </Switch>
        );
    }
}

export default Routes;

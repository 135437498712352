/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable react/prop-types */
import React from 'react';
import { RouteProps } from 'react-router-dom';
import { RESOURCES } from '../config/resources';
import { AvailableLanguage, DEFAULT_LANGUAGE } from '../models/Statics';
import { Utils } from '../models/Utils';
import * as axios from 'axios';

export type Props = RouteProps & {};

export default class PageBase extends React.Component {
    constructor(props: Props) {
        super(props);
    }
    protected _pageLanguage: string | undefined;
    protected GetText(key: string, language: AvailableLanguage): string {
        if (Utils.StringIsNullOrEmpty(key)) return '';
        if (Utils.StringIsNullOrEmpty(language)) {
            language = DEFAULT_LANGUAGE;
        }

        return RESOURCES[`${key}_${language}`];
    }
    protected async GetHTML(template: string, language: AvailableLanguage): Promise<string> {
        if (Utils.StringIsNullOrEmpty(template)) return '';
        if (Utils.StringIsNullOrEmpty(language)) {
            language = DEFAULT_LANGUAGE;
        }

        try {
            const result = await axios.default.get<string>(`../templates/${template}_${language}.html`);
            if (result.statusText === 'OK') {
                return result.data;
            } else {
                console.log('Error on fetching html data');
                return '';
            }
        } catch (error) {
            console.log(error);
            return '';
        }
    }
    render() {
        return <></>;
    }
}

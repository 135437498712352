import * as axios from 'axios';
import { Config } from '../../config/Config';
import { IEmailMessage } from '../../models/Interfaces';
export class EmailService {
    private _axios: axios.AxiosStatic;
    constructor() {
        this._axios = axios.default;
    }
    public async Send(message: IEmailMessage): Promise<boolean> {
        try {
            const result = await this._axios.post(Config.EmailServerEndpoint, message);
            if (result.statusText === 'OK' || result.statusText === 'Accepted') {
                return true;
            } else {
                return false;
            }
        } catch (error) {
            console.log('An error has occurred while sending an email:', error);
            return false;
        }
    }
}

import { createMuiTheme } from '@material-ui/core/styles';

export const RPM_COLOR_PRIMARY = '#6971F6';
export const RPM_COLOUR_PRIMARY_LIGHT = '#F1F0FE';
export const RPM_COLOR_SECONDARY = '#EF7869';
export const RPM_COLOR_GREEN = '#00a881';
export const RPM_COLOR_DARK_GREEN = '#00a8814d';
export const RPM_COLOR_RED = '#c11739';

const FONT_WEIGHT_BOLD = 300;
// const FONT_WEIGHT_LIGHT = 300;
// const FONT_WEIGHT_NORMAL = 'normal';
const FONT_COLOR_PRIMARY = '#ffffff';
const FONT_COLOR_SECONDARY = '#708596';
// const FONT_FAMILY_MONTSERRAT = 'Montserrat';
// const FONT_FAMILY_OPEN_SANS = 'Open Sans';

const rpmTheme = createMuiTheme({
    spacing: 8,
    palette: {
        type: 'dark',
        primary: {
            main: RPM_COLOR_PRIMARY,
            light: RPM_COLOR_SECONDARY,
            dark: RPM_COLOR_SECONDARY,
            contrastText: '#ffffff',
        },
        secondary: {
            main: RPM_COLOR_SECONDARY,
            light: RPM_COLOR_SECONDARY,
            dark: RPM_COLOR_SECONDARY,
            contrastText: '#ffffff',
        },
        success: {
            main: RPM_COLOR_GREEN,
        },
        error: {
            main: RPM_COLOR_RED,
        },
        action: {
            disabled: '#D5D7F7',
            disabledBackground: 'rgba(0, 0, 0, 0.03)',
        },
        text: {
            primary: FONT_COLOR_PRIMARY,
            secondary: FONT_COLOR_SECONDARY,
        },
    },
    typography: {
        fontFamily: 'inherit',
        h1: {
            // fontWeight: FONT_WEIGHT_BOLD_MONTSERRAT,
            fontSize: '2em',
            //  color: FONT_COLOR_PRIMARY,
        },
        h2: {
            fontWeight: FONT_WEIGHT_BOLD,
            fontSize: '1.75em',
            // color: FONT_COLOR_PRIMARY,
        },
        h3: {
            fontWeight: FONT_WEIGHT_BOLD,
            fontSize: '1.35em',
            // color: FONT_COLOR_PRIMARY,
        },
        h4: {
            // fontWeight: FONT_WEIGHT_BOLD_MONTSERRAT,
            fontSize: '1.1em',
            // color: FONT_COLOR_PRIMARY,
        },
        h5: {
            // fontWeight: FONT_WEIGHT_BOLD_MONTSERRAT,
            fontSize: '0.9em',
            // color: FONT_COLOR_PRIMARY,
        },
        h6: {
            // fontFamily: FONT_FAMILY_OPEN_SANS,
            fontSize: '0.7em',
            // color: FONT_COLOR_PRIMARY,
        },
        body1: {
            // fontFamily: FONT_FAMILY_OPEN_SANS,
            // fontWeight: FONT_WEIGHT_NORMAL,
            fontSize: '14px',
            // color: FONT_COLOR_PRIMARY,
        },
        body2: {
            // fontFamily: FONT_FAMILY_OPEN_SANS,
            // fontWeight: FONT_WEIGHT_NORMAL,
            // fontSize: '12px',
            //  color: FONT_COLOR_PRIMARY,
        },
        subtitle1: {
            // fontFamily: FONT_FAMILY_OPEN_SANS,
            // fontSize: '14px',
            // color: FONT_COLOR_PRIMARY,
            // fontWeight: FONT_WEIGHT_LIGHT,
        },
        subtitle2: {
            // fontFamily: FONT_FAMILY_OPEN_SANS,
            // fontSize: '12px',
            // color: FONT_COLOR_PRIMARY,
            //  fontWeight: FONT_WEIGHT_LIGHT,
        },
        button: {
            // fontSize: '16px',
            // fontWeight: 500,
            // textTransform: 'none',
            // color: FONT_COLOR_PRIMARY,
        },
        caption: {
            color: FONT_COLOR_PRIMARY,
        },
        overline: {
            color: FONT_COLOR_PRIMARY,
        },
    },
});

export default rpmTheme;

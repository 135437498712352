import { Box, Typography } from '@material-ui/core';
import React, { Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import _ from 'underscore';
import { AvailableLanguage, AVAILABLE_LANGUAGES, DEFAULT_LANGUAGE } from '../../models/Statics';
import { Utils } from '../../models/Utils';
import PageBase from '../PageBase';

interface IData {
    privacyText: string;
}

const initialState: IData = {
    privacyText: '',
};

export default class Privacy extends PageBase {
    constructor(props: any) {
        super(props);
        this._pageLanguage = props.match.params.language ?? DEFAULT_LANGUAGE;
    }

    state = initialState;

    componentDidMount() {
        this.GetHTML(`privacy`, this._pageLanguage as AvailableLanguage).then((privacy) => {
            this.setState({ privacyText: privacy });
        });
    }

    render() {
        if (Utils.StringIsNullOrEmpty(this._pageLanguage) || !_.contains(AVAILABLE_LANGUAGES, this._pageLanguage)) {
            this._pageLanguage = DEFAULT_LANGUAGE;
            return <Redirect to={`/${DEFAULT_LANGUAGE}/privacy`} />;
        }
        return (
            <Fragment>
                <Box mt={{ xs: 5, sm: 0 }} component={'header'} textAlign={{ xs: null, sm: 'center' }}>
                    <Typography variant="h2">
                        {this.GetText(`privacy_title`, this._pageLanguage as AvailableLanguage)}
                    </Typography>
                    <Typography>
                        {Utils.RenderSafeHtml(this.GetText(`privacy_intro`, this._pageLanguage as AvailableLanguage))}
                    </Typography>
                </Box>
                <Box>{Utils.RenderSafeHtml(this.state.privacyText)}</Box>
            </Fragment>
        );
    }
}

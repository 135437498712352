import { Box } from '@material-ui/core';
import React from 'react';
import { IChildren } from '../../models/Interfaces';
import { DEFAULT_LANGUAGE } from '../../models/Statics';
import { Utils } from '../../models/Utils';

export type Props = {
    children?: IChildren;
};
export const initialState = {
    language: DEFAULT_LANGUAGE,
};

export default class LayoutBase extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
        const url = props.children?.props.location.pathname as string;
        this._pageLanguage = Utils.GetLanguageFromUrl(url);
        this._currentUrl = Utils.GetUrlLocalWithoutLanguage(url);
    }
    protected _pageLanguage: string | undefined;
    protected _currentUrl: string;
    state = initialState;

    public render() {
        return <Box></Box>;
    }
}

import React, { Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import _ from 'underscore';
import { AvailableLanguage, AVAILABLE_LANGUAGES, DEFAULT_LANGUAGE } from '../../models/Statics';
import { Utils } from '../../models/Utils';
import PageBase from '../PageBase';

interface IData {
    homeText: string;
}

const initialState: IData = {
    homeText: '',
};

export default class Home extends PageBase {
    constructor(props: any) {
        super(props);
        this._pageLanguage = props.match.params.language ?? DEFAULT_LANGUAGE;
    }
    state = initialState;

    componentDidMount() {
        this.GetHTML(`home`, this._pageLanguage as AvailableLanguage).then((home) => {
            this.setState({ homeText: home });
        });
    }
    render() {
        if (Utils.StringIsNullOrEmpty(this._pageLanguage) || !_.contains(AVAILABLE_LANGUAGES, this._pageLanguage)) {
            this._pageLanguage = DEFAULT_LANGUAGE;
            return <Redirect to={`/${DEFAULT_LANGUAGE}/home`} />;
        }
        return <Fragment>{Utils.RenderSafeHtml(this.state.homeText)}</Fragment>;
    }
}

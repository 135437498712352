import React from 'react';
import { Route, RouteComponentProps, RouteProps } from 'react-router-dom';

interface Props extends RouteProps {
    component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
    layout: React.ComponentType<any>;
}

const RouteWithLayout = ({ component: Component, layout: Layout, ...rest }: Props): JSX.Element => {
    return (
        <Route
            {...rest}
            render={(props) => (
                <Layout>
                    <Component {...props} />
                </Layout>
            )}
        />
    );
};

export default RouteWithLayout;

import { Box } from '@material-ui/core';
import React from 'react';
import { Config } from '../../config/Config';
import styles from '../../styles/Footer.module.scss';

// eslint-disable-next-line @typescript-eslint/ban-types
type Props = {};

export default class Footer extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }
    public render() {
        return (
            <Box id={styles.footer} component={'footer'}>
                <Box className={styles.inner}>
                    <Box className={styles.flex}>
                        <Box className={styles.copyright}>
                            &copy; Design: <a href="https://templated.co">TEMPLATED</a>. {' | '} &copy; All rights
                            reserved:
                            <a href="https://www.theresurrectionpower.org">Resurrection Power Ministries</a>.
                        </Box>
                        <Box className={styles.icons} component={'ul'}>
                            <Box component={'li'}>
                                <a
                                    href={Config.FacebookLink}
                                    rel="noreferrer"
                                    target="_blank"
                                    className="icon fa-facebook"
                                >
                                    <Box className={styles.label} component={'span'}>
                                        acebook
                                    </Box>
                                </a>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        );
    }
}

import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { RESOURCES } from '../../config/resources';
import styles from '../../styles/Contact.module.scss';
import PageBase from '../PageBase';
import { FormField } from '../../components/FormField';
import { FormFieldDivider } from '../../components/FormFieldDivider';
import { Redirect } from 'react-router-dom';
import _ from 'underscore';
import { AVAILABLE_LANGUAGES, DEFAULT_LANGUAGE } from '../../models/Statics';
import { Utils, VALID_EMAIL_REGEX } from '../../models/Utils';
import CountryAutoSelect from '../../components/CountryAutoSelect';
import { RmButton } from '../../components/RmButton';
import { EmailService } from '../../services/email/EmailService';
import { Config } from '../../config/Config';
import PrivacyNotice from '../../components/PrivacyNotice';
import { COUNTRIES } from '../../config/countries';

interface IData {
    email: string;
    lastName: string;
    firstName: string;
    subject: string;
    country: string;
    error: string;
    message: string;
    success: boolean;
    isLoading: boolean;
}

const initialState: IData = {
    email: '',
    lastName: '',
    firstName: '',
    subject: '',
    country: '',
    error: '',
    message: '',
    success: false,
    isLoading: false,
};

export default class Contact extends PageBase {
    constructor(props: any) {
        super(props);
        this.OnSubmit = this.OnSubmit.bind(this);
        this.OnInputChange = this.OnInputChange.bind(this);
        this._emailService = new EmailService();
        this._pageLanguage = props.match.params.language ?? DEFAULT_LANGUAGE;
    }

    private _emailService: EmailService;

    state = initialState;

    private async OnSubmit(e: any) {
        e.preventDefault();
        if (!this.ValidateForm()) return;
        this.setState({ isLoading: true });

        try {
            const { email, lastName, firstName, subject, country, message }: IData = this.state;
            const body = `<p>Name: ${firstName} ${lastName}</p><p>Country: ${
                COUNTRIES.find((c) => {
                    return c.code === country;
                })?.name
            } </p><p>Message:</p><p>${message}</p>`;
            const result = await this._emailService.Send({
                SenderEmail: email,
                Body: body,
                SenderName: `${firstName} ${lastName}`,
                Subject: subject,
                Recipients: [Config.AdminEmail],
            });

            if (result) {
                initialState.success = true;
                this.setState(initialState);
            } else {
                this.setState({ error: 'An error has occurred, try again!' });
            }
        } catch (error) {
            this.setState({ error: error.message });
        } finally {
            this.setState({ isLoading: false });
        }
    }

    private ValidateForm(): boolean {
        const { email, lastName, firstName, country, subject, message }: IData = this.state;

        if (!email || !VALID_EMAIL_REGEX.test(email)) {
            this.setState({ error: 'Invalid email address' });
            return false;
        }
        if (!lastName) {
            this.setState({ error: 'Provide your last name' });
            return false;
        }
        if (!firstName) {
            this.setState({ error: 'Provide your first name' });
            return false;
        }
        if (!country) {
            this.setState({ error: 'Provide your country' });
            return false;
        }
        if (!subject) {
            this.setState({ error: 'Provide the subject' });
            return false;
        }
        if (!message) {
            this.setState({ error: 'Provide the message' });
            return false;
        }
        this.setState({ error: '' });

        return true;
    }

    private OnInputChange(e: any) {
        switch (e.target.name) {
            case 'email':
                this.setState({ email: e.target.value });
                break;
            case 'firstname':
                this.setState({ firstName: e.target.value });
                break;
            case 'lastname':
                this.setState({ lastName: e.target.value });
                break;
            case 'message':
                this.setState({ message: e.target.value });
                break;
            case 'subject':
                this.setState({ subject: e.target.value });
                break;
            default:
                break;
        }
    }

    public render() {
        if (Utils.StringIsNullOrEmpty(this._pageLanguage) || !_.contains(AVAILABLE_LANGUAGES, this._pageLanguage)) {
            this._pageLanguage = DEFAULT_LANGUAGE;
            return <Redirect to={`/${DEFAULT_LANGUAGE}/contact`} />;
        }

        return (
            <Box mx="auto" maxWidth={600} flexDirection="column">
                <Box alignItems="center" my={2}>
                    <Box textAlign="center">
                        <Box mb={2}>
                            <Typography variant="h2" className={styles.title}>
                                {RESOURCES[`contact_title_${this._pageLanguage}`]}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                {this.state.error && (
                    <Box py={0.5} px={1} mb={1} className={styles.errorBox} textAlign="center">
                        <Typography variant="subtitle1" color="inherit">
                            {this.state.error}{' '}
                        </Typography>
                    </Box>
                )}

                {this.state.success && (
                    <Box py={0.5} px={1} mb={1} className={styles.successBox} textAlign="center">
                        <Typography variant="subtitle1" color="inherit">
                            {RESOURCES[`email_sent_success_${this._pageLanguage}`]}
                        </Typography>
                    </Box>
                )}

                {!this.state.success && (
                    <form onSubmit={this.OnSubmit}>
                        <Box px={2} className={styles.formBody}>
                            <FormField
                                onChange={this.OnInputChange}
                                focused={true}
                                value={this.state.firstName}
                                isRequired={true}
                                id="firstname"
                                inputType={'text'}
                                label={RESOURCES[`firstname_${this._pageLanguage}`]}
                            />
                            <FormFieldDivider />
                            <FormField
                                onChange={this.OnInputChange}
                                value={this.state.lastName}
                                isRequired={true}
                                id="lastname"
                                inputType={'text'}
                                label={RESOURCES[`lastname_${this._pageLanguage}`]}
                            />
                            <FormFieldDivider />
                            <FormField
                                onChange={this.OnInputChange}
                                value={this.state.email}
                                isRequired={true}
                                id="email"
                                inputType={'email'}
                                label="Email"
                            />
                            <FormFieldDivider />

                            <CountryAutoSelect
                                id="cont-country"
                                onSelect={(value) => {
                                    this.setState({ country: value });
                                }}
                                label={RESOURCES[`country_${this._pageLanguage}`]}
                                placeholder={RESOURCES[`country_${this._pageLanguage}`]}
                            />
                            <FormFieldDivider />
                            <FormField
                                onChange={this.OnInputChange}
                                value={this.state.subject}
                                isRequired={true}
                                id="subject"
                                inputType={'text'}
                                label={RESOURCES[`subject_${this._pageLanguage}`]}
                            />
                            <FormFieldDivider />
                            <FormField
                                onChange={this.OnInputChange}
                                value={this.state.message}
                                isRequired={false}
                                id="message"
                                inputType={'textarea'}
                                label={RESOURCES[`message_${this._pageLanguage}`]}
                            />
                        </Box>

                        <Box
                            width="100%"
                            justifyContent={{ xs: 'flex-center', sm: 'flex-end' }}
                            justifyItems="flex-end"
                            mt={2}
                            display="flex"
                        >
                            <RmButton
                                size="fit"
                                color="special"
                                type="submit"
                                name={RESOURCES[`register_${this._pageLanguage}`]}
                            />
                        </Box>
                    </form>
                )}
                <Box display="flex" px={{ xs: 2, sm: null }}>
                    <PrivacyNotice language={this._pageLanguage ?? DEFAULT_LANGUAGE} />
                </Box>
            </Box>
        );
    }
}

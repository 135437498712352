/* eslint-disable @typescript-eslint/no-useless-constructor */
import { Box, Input, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import React from 'react';
import styles from '../styles/FormField.module.scss';
import classNames from 'classnames';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { IIdText } from '../models/Interfaces';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

export type InputType = 'number' | 'text' | 'email' | 'select' | 'textarea' | 'password' | 'autocomplete';

type Props = {
    inputType: InputType;
    label: string;
    id: string;
    value?: string;
    source?: IIdText[] /**only if type is select */;
    isRequired: boolean;
    onChange?: (e: any) => void;
    autoComplete?: string;
    focused?: boolean;
};

export class FormField extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    public render() {
        return (
            <Box
                display="flex"
                alignContent="space-between"
                flexDirection={{ xs: 'column', sm: 'row' }}
                width="100%"
                my={2}
                alignItems={{ sm: 'center' }}
            >
                <Box width={{ xs: '100%', sm: 90 }}>
                    <InputLabel id={`label-${this.props.id}`}>{this.props.label}</InputLabel>
                </Box>

                <Box ml={{ sm: 4 }} flexGrow={1}>
                    {this.props.inputType === 'select' && (
                        <Select
                            onChange={this.props.onChange}
                            required={this.props.isRequired}
                            placeholder={this.props.label}
                            fullWidth={true}
                            labelId={`label-${this.props.id}`}
                            name={this.props.id}
                            value={this.props.value}
                            disableUnderline={true}
                            className={styles.selectInput}
                        >
                            {this.props.source &&
                                this.props.source.length > 0 &&
                                this.props.source.map((s) => {
                                    return (
                                        <MenuItem key={s.id} value={s.id}>
                                            {s.text}
                                        </MenuItem>
                                    );
                                })}
                        </Select>
                    )}
                    {this.props.inputType !== 'select' && this.props.inputType !== 'autocomplete' && (
                        <Input
                            className={classNames({
                                [styles.textareaInput]: this.props.inputType === 'textarea',
                            })}
                            id={this.props.id}
                            type={this.props.inputType}
                            fullWidth={true}
                            placeholder={this.props.label}
                            color="primary"
                            required={this.props.isRequired}
                            onChange={this.props.onChange}
                            multiline={this.props.inputType === 'textarea'}
                            rows={this.props.inputType === 'textarea' ? 5 : 1}
                            rowsMax={this.props.inputType === 'textarea' ? 5 : 1}
                            autoComplete={this.props.autoComplete}
                            autoFocus={this.props.focused}
                            disableUnderline={true}
                            key={this.props.id}
                            value={this.props.value}
                            name={this.props.id}
                        />
                    )}
                    {this.props.inputType === 'autocomplete' && (
                        <Autocomplete
                            id={this.props.id}
                            fullWidth={true}
                            options={this.props.source as IIdText[]}
                            getOptionLabel={(option) => option.text}
                            getOptionSelected={(option, value) => option.id === value.id}
                            classes={{
                                root: styles.autoCompleteRoot,
                                paper: styles.autoCompletepaper,
                                listbox: styles.autoCompletelistbox,
                                inputRoot: styles.autoCompleteinputRoot,
                                endAdornment: styles.autoCompleteendAdornment,
                            }}
                            onChange={(_e: any, value: any) => {
                                if (this.props.onChange) {
                                    this.props.onChange(value.id);
                                }
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="filled"
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'new-password', // disable autocomplete and autofill
                                    }}
                                    fullWidth={true}
                                    classes={{
                                        root: styles.textFieldRoot,
                                    }}
                                    placeholder={this.props.label}
                                />
                            )}
                            renderOption={(option, { inputValue }) => {
                                const matches = match(option.text, inputValue);
                                const parts = parse(option.text, matches);

                                return (
                                    <Box>
                                        {parts.map((part, index) => (
                                            <Box
                                                component={'span'}
                                                key={index}
                                                style={{ fontWeight: part.highlight ? 700 : 400 }}
                                            >
                                                {part.text}
                                            </Box>
                                        ))}
                                    </Box>
                                );
                            }}
                        />
                    )}
                </Box>
            </Box>
        );
    }
}

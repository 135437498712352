import { Box, InputLabel, TextField } from '@material-ui/core';
import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ICountryType } from '../models/Interfaces';
import { COUNTRIES } from '../config/countries';
import styles from '../styles/CountryAutoSelect.module.scss';
import { Utils } from '../models/Utils';

// eslint-disable-next-line @typescript-eslint/ban-types
type Props = {
    onSelect?: (value: string) => void;
    label?: string;
    placeholder?: string;
    id: string;
};

export default class CountryAutoSelect extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    public render() {
        return (
            <Box
                display="flex"
                alignContent="space-between"
                flexDirection={{ xs: 'column', sm: 'row' }}
                width="100%"
                my={2}
                alignItems={{ sm: 'center' }}
            >
                {this.props.label && (
                    <Box width={{ xs: '100%', sm: 90 }}>
                        <InputLabel id={`label-autoselect}`}>{this.props.label}</InputLabel>
                    </Box>
                )}

                <Box ml={{ sm: 4 }} flexGrow={1}>
                    <Autocomplete
                        classes={{
                            root: styles.root,
                            paper: styles.paper,
                            listbox: styles.listbox,
                            inputRoot: styles.inputRoot,
                            endAdornment: styles.endAdornment,
                        }}
                        id={this.props.id}
                        fullWidth={true}
                        options={COUNTRIES as ICountryType[]}
                        onChange={(_e: any, value: any) => {
                            if (this.props.onSelect) {
                                this.props.onSelect(value.code);
                            }
                        }}
                        autoHighlight
                        getOptionLabel={(option) => option.name}
                        getOptionSelected={(option, value) => option.code === value.code}
                        renderOption={(option) => (
                            <React.Fragment>
                                <span>{Utils.CountryToFlag(option.code)}</span>
                                {option.name} ({option.code})
                            </React.Fragment>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="filled"
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                }}
                                fullWidth={true}
                                classes={{
                                    root: styles.textFieldRoot,
                                }}
                                placeholder={this.props.placeholder}
                            />
                        )}
                    />
                </Box>
            </Box>
        );
    }
}

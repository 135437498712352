export const RESOURCES: { [key: string]: string } = {
    event_registration_title_en: 'Event - Free Registration',
    event_registration_title_cz: 'Akce - Registrace Zdarma',
    event_registration_title_fr: 'Evenement - Enregistrement Gratuit',
    event_registration_intro_en:
        'Please register to participate to the event. You will receive the ZOOM link in your inbox.',
    event_registration_intro_cz:
        'Chcete-li se akce zúčastnit, zaregistrujte se prosím. Do své emailové schránky obdržíte ZOOM link.',
    event_registration_intro_fr:
        'Enregistrez-vous SVP pour participer au programme. Vous recevrez le lien ZOOM dans votre courriel',

    firstname_en: 'First Name',
    firstname_fr: 'Prenom(s)',
    firstname_cz: 'Jméno',

    lastname_en: 'Last Name',
    lastname_fr: 'Nom',
    lastname_cz: 'Příjmení',

    country_en: 'Country',
    country_fr: 'Pays',
    country_cz: 'Země',

    city_en: 'City',
    city_fr: 'Ville',
    city_cz: 'Město',

    subject_en: 'Subject',
    subject_fr: 'Sujet',
    subject_cz: 'Předmět',
    language_en: 'Language',
    language_fr: 'Langue',
    language_cz: 'Jazyk',

    message_en: 'message',
    message_fr: 'Message',
    message_cz: 'Zpráva',

    register_question_en: 'Question?',
    register_question_fr: 'Question?',
    register_question_cz: 'Otázky?',

    register_en: 'Send',
    register_fr: 'Envoyer',
    register_cz: 'Poslat',
    contact_title_en: 'Send us a message',
    contact_title_fr: 'Envoyez-nous un message',
    contact_title_cz: 'Kontaktujte nás',

    email_sent_success_en: 'Message sent successfully!',
    email_sent_success_fr: 'Message envoyé avec succes!',
    email_sent_success_cz: 'Zpráva úspěšně odeslána !',

    event_registration_success_en: 'Your registration was successfull!',
    event_registration_success_fr: 'Enregistrement fait avec succes!',
    event_registration_success_cz: 'Vaše registrace byla úspěšná !',
    home_intro_en: 'We are committed to win souls for the Kingdom of God',
    home_intro_cz: 'Jsme odhodláni získávat duše pro Boží království',
    home_intro_fr: 'Nous nous engageons à gagner des âmes pour le Royaume de Dieu',
    welcome_intro_fr: 'Bienvenue sur les pages du Resurrection Power Ministries',
    welcome_intro_en: 'Welcome to the Resurrection Power Ministries',
    welcome_intro_cz: 'Vítejte na stránkách Resurrection Power Ministries.',

    event_registration_menu_en: 'Event Registration',
    event_registration_menu_cz: 'Akce Registrace',
    event_registration_menu_fr: 'Evenement Enregistrement',
    contact_menu_en: 'Contact',
    contact_menu_cz: 'Kontakt',
    contact_menu_fr: 'Contact',

    privacy_title_en: 'Privacy rules (GDPR)',
    privacy_title_fr: 'Règles de confidentialité (GDPR)',
    privacy_title_cz: 'Pravidla ochrany osobních údajů (GDPR)',
    privacy_intro_en:
        'We do appreciate your trust and we would like to assure you that Resurrection Power Ministries cares for protection of your personal data while collecting them and processing them. We abide by relevant laws while using your personal data.',
    privacy_intro_fr:
        "Nous apprécions votre confiance et nous tenons à vous assurer que Resurrection Power Ministries se soucie de la protection de vos données personnelles lors de leur collecte et de leur traitement. Nous respectons les lois en vigueur lors de l'utilisation de vos données personnelles.",
    privacy_intro_cz:
        'Vážíme si vaší důvěry a rádi bychom vás ujistili, že Resurrection Power Ministries dbá na ochranu vašich osobních údajů při jejich shromažďování i zpracovávání. V případě použití vašich osobních údajů dodržujeme vždy příslušné právní předpisy.',
    privacy_notice_en: 'By submitting, you agree to our',
    privacy_notice_fr: 'En soumettant, vous acceptez notre',
    privacy_notice_cz: 'Odesláním zprávy souhlasíte s našimi ',
    privacy_link_en: 'Privacy Policy',
    privacy_link_fr: 'Règles de confidentialité',
    privacy_link_cz: 'Zásadami ochrany informací',
};

import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { RESOURCES } from '../config/resources';
import styles from '../styles/PrivacyNotice.module.scss';
type Props = {
    language: string;
};

export default class PrivacyNotice extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <Box my={2}>
                <Typography variant="h5">
                    {RESOURCES[`privacy_notice_${this.props.language}`]}
                    &nbsp;
                    <a href={`/${this.props.language}/privacy`}>
                        <Box className={styles.link} component={'span'}>
                            {RESOURCES[`privacy_link_${this.props.language}`]}
                        </Box>
                    </a>
                </Typography>
            </Box>
        );
    }
}

import { Box, Button } from '@material-ui/core';
import React from 'react';

type Props = {
    name: string;
    size: 'small' | 'fit';
    disabled?: boolean;
    color?: 'special' | 'default' | 'alt';
    onClick?: (e: any) => void;
    type?: 'submit' | 'button' | 'reset';
};

export class RmButton extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <Box
                width={{ xs: '100%', sm: 250 }}
                height={50}
                alignItems="center"
                display="flex"
                mx={{ xs: 2, sm: null }}
            >
                <Button
                    className={`button ${this.props.color !== 'default' ? this.props.color : ''} ${this.props.size}`}
                    disabled={this.props.disabled}
                    fullWidth={this.props.size === 'fit'}
                    type={this.props.type}
                    onClick={this.props.onClick}
                >
                    {this.props.name}
                </Button>
            </Box>
        );
    }
}

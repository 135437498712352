/* eslint-disable no-useless-escape */
import _ from 'underscore';
import { AvailableLanguage, AVAILABLE_LANGUAGES, DEFAULT_LANGUAGE } from './Statics';
import HTMLReactParser from 'html-react-parser';
import { filterXSS } from 'xss';
export const VALID_EMAIL_REGEX = RegExp(
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
);

export class Utils {
    static StringIsNullOrEmpty(str: string | undefined): boolean {
        return _.isNull(str) || _.isUndefined(str) || str.length === 0;
    }
    static IsNull(value: any): boolean {
        return _.isNull(value) || _.isUndefined(value);
    }

    static EnsureArgument(arg: any, argName: string) {
        if (typeof arg == 'string' && Utils.StringIsNullOrEmpty(arg)) {
            throw new Error(argName + ' string cannot be null or empty.');
        }

        if (arg == null) {
            throw new Error(argName + ' cannot be null.');
        }

        if (!arg) {
            throw new Error(argName + ' cannot be undefined.');
        }
    }

    static IsValidEmail(email: string): boolean {
        return /^[a-z0-9_\.\+-]*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
    }

    static GetLanguageFromUrl(url: string): AvailableLanguage | undefined {
        if (this.StringIsNullOrEmpty(url) || url === '/') return DEFAULT_LANGUAGE;

        const all = url.split('/');

        if (all.length < 2) return undefined;

        if (all.every((l) => !_.contains(AVAILABLE_LANGUAGES, l))) return DEFAULT_LANGUAGE;

        const lang = all[1];

        if (!_.contains(AVAILABLE_LANGUAGES, lang)) {
            return undefined;
        }
        return lang as AvailableLanguage;
    }

    static GetUrlLocalWithoutLanguage(url: string): string {
        if (this.StringIsNullOrEmpty(url) || url === '/') return '';

        const all = url.split('/');
        if (_.contains(AVAILABLE_LANGUAGES, all[1])) {
            return url.replace(`/${all[1]}`, '');
        }

        return url;
    }

    static CountryToFlag(isoCode: string): string {
        return typeof String.fromCodePoint !== 'undefined'
            ? isoCode.toUpperCase().replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
            : isoCode;
    }

    static RenderSafeHtml(html: string) {
        if (this.StringIsNullOrEmpty(html)) return '';
        return HTMLReactParser(filterXSS(html));
    }
}
